import { localePath } from '../constants';
import { i18n } from '@/plugins/i18n';
export default {
    computed: {
        getTrad() {
            return (id) => {
                return i18n.t(localePath(id));
            };
        },
    },
};
